.App {
  text-align: center;
  background-color: #f0b7a4;
  height: 100vh;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 0 !important;
}

p {
  color: white;
  line-height: 1.5em;
}

h1, h2, h3, h4 {
  color: white
}

.Left {
  text-align: left;
  font-weight: 100;
  line-height: 1.5em;
  font-size: 0.9em;
  padding: 4em !important;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.Right {
  padding: 6em !important;
}

.polygon {
  cursor: pointer;
  transition: 1s;
}

a {
  color: white !important;
} 

a:hover {
  color: #f1d1b5 !important;
}

.Social {
  margin-right: 0.5em;
}

.SecondPage {
  text-align: center;
  background-color: #f0b7a4;
  min-height: 100vh;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 0 !important;
}

.VMC-Logo {
  width: 10em;
}

.Ios-app {
  width: 35%
}
.Android-app {
  width: 40%
}

.Bird {
  position: fixed;
  top: -1em;
  right: -1em;
  z-index: 5;
}

.HeaderTitle {
  margin-left: 6em;
  margin-top: 2em;
}

.Footer {
  background-color: #305f72;
  padding: 1em 5em 1em 5em;
  display: flex;
  justify-content: space-between
}

.Footer p {
  color: white;
  margin: 0;
}

h1 { 
  font-size: 3.5rem !important; 
}

.ArrowDown {
  cursor: pointer;
}

.ArrowDownClick {
  display: flex;
  justify-content: center;
  font-size: 2em;
  margin-top: 1em
}

.Chatbot {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 4em !important;
}

.grid-container2 {
  display: inline-grid;
  grid-template-columns: 1fr 20fr 1fr;
  grid-template-rows: 1fr 10fr 1fr;
  grid-template-areas: ". . ." ". . ." ". . .";
  margin: 5vw 10vw 5vw 10vw;
  flex: 1
}

.navCircle {
  width: 16px;
  height: 16px;
  margin-top: 16px;
  border-radius: 8px;
  border: 2px solid white;
  transition: 0.5s;
}

.navCircle:hover {
  background-color: white;
  transition: 0.5s;
  cursor: pointer;
}

.navCircleActive:hover {
  cursor: pointer;
}

.navCircleActive {
  background-color: white;
  transition: 0.5s;
  width: 16px;
  height: 16px;
  margin-top: 16px;
  border-radius: 8px;
  border: 2px solid white
}
